import defaultPlaceholder from './default.svg'
import requestPlaceholder from './place-holder-request.svg'
import countryPlaceholder from './place_holder_Country.svg'
import newsPlaceholder from './place_holder_News.svg'
import campaignPlaceholder from './place_holder_campaign.svg'
import discoverPlaceholder from './place_holder_ discover.svg'
import offerPlaceholder from './place_holder_offer.svg'
import wishlistPlaceholder from './place_holder_wishlist.svg'
import influencerPlaceholder from './place_holder_influenceur.svg'
import horizontalBarChart from './placeholder_chart_horizental_bar.svg'
import verticalBarChart from './placeholder_chart_vertical_bar.svg'
import table from './place_holder_table2.svg'
import pieChart from './place_holder_Piechart.svg'
import {
   default as productPlaceholder,
   default as salesPlaceholder,
} from './place_holder_Sales.svg'

export const placeholdersDataSources = {
  default: defaultPlaceholder,
  campaigns: campaignPlaceholder,
  influencer: influencerPlaceholder,
  sales: salesPlaceholder,
  product: productPlaceholder,
  request: requestPlaceholder,
  news: newsPlaceholder,
  offer: offerPlaceholder,
  country: countryPlaceholder,
  wishlist: wishlistPlaceholder,
  discover: discoverPlaceholder,
  chart: {
    h_bar: horizontalBarChart,
    v_bar: verticalBarChart,
    pie: pieChart,
  },
  admin: {},
  table: table,
  trend: {
    campaigns: campaignPlaceholder,
    influencer: influencerPlaceholder,
    sales: salesPlaceholder,
    product: productPlaceholder,
    request: requestPlaceholder,
  },
}
