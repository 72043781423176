import { useTranslation } from 'react-i18next'
import { placeholdersDataSources } from '../../../assets/images/placeholders'

const sizes = {
  xs: 100,
  ss: 150,
  small: 200,
  medium: 250,
  default: 300,
  large: 400,
  larger: 500,
}
function PlaceHolder({ variant, label = 'No Data', size = 'ss' }) {
  const { t } = useTranslation(['translation'])
  const images = {
    horizontalBar: placeholdersDataSources.chart.h_bar,
    verticalBar: placeholdersDataSources.chart.v_bar,
    pieChart: placeholdersDataSources.chart.pie,
    influencer: placeholdersDataSources.influencer,
    table: placeholdersDataSources.table,
  }
  const ImgPlaceHolder = variant ? images[variant] : images['verticalBar']

  return (
    <div className='d-flex justify-content-center align-items-center flex-column h-100'>
      <div style={{ width: sizes[size], height: 'auto' }}>{<img src={ImgPlaceHolder} />}</div>
      {label.length > 0 && (
        <div>
          <h5 style={{ fontFamily: 'inherit' }} className='h5'>
            {t('NO_DATA', { ns: 'translation' })}
          </h5>
        </div>
      )}
    </div>
  )
}

export default PlaceHolder
