export const env = process.env.REACT_APP_STAGE

const config = {
  prod: {
    apiKey: "AIzaSyAc6zE83EFHTnP9LCudRbHVyR-GJDIXEV4",
    authDomain: "nutrimuscle-ambassadeur.firebaseapp.com",
    databaseURL: "https://nutrimuscle-ambassadeur-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "nutrimuscle-ambassadeur",
    storageBucket: "nutrimuscle-ambassadeur.appspot.com",
    messagingSenderId: "235054705431",
    appId: "1:235054705431:web:df0479717fcbd7c985e552",
    measurementId: "G-EEPG8LQ0PD"
  },
  local: {
    apiKey: "AIzaSyBFLrF6gfU6_nPdvwObWNxUYeMWGHnxz90",
    authDomain: "nutrimuscle-dev.firebaseapp.com",
    databaseURL: "https://nutrimuscle-dev-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "nutrimuscle-dev",
    storageBucket: "nutrimuscle-dev.appspot.com",
    messagingSenderId: "1060766880926",
    appId: "1:1060766880926:web:8e79e94c51ad767f76978f"
  },
  dev: {
    apiKey: "AIzaSyBFLrF6gfU6_nPdvwObWNxUYeMWGHnxz90",
    authDomain: "nutrimuscle-dev.firebaseapp.com",
    databaseURL: "https://nutrimuscle-dev-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "nutrimuscle-dev",
    storageBucket: "nutrimuscle-dev.appspot.com",
    messagingSenderId: "1060766880926",
    appId: "1:1060766880926:web:8e79e94c51ad767f76978f"
  },
}

export const socialMediasImages = {
  instagram:
    'https://res.cloudinary.com/tellmedia/image/upload/v1566481795/shopmyinfluens/icons/instagram.svg',
  twitter:
    'https://res.cloudinary.com/tellmedia/image/upload/v1566481795/shopmyinfluens/icons/twitter.svg',
  facebook:
    'https://res.cloudinary.com/tellmedia/image/upload/v1566481799/shopmyinfluens/icons/facebook.svg',
  youtube:
    'https://res.cloudinary.com/tellmedia/image/upload/v1566811998/shopmyinfluens/icons/youtube.svg',
  pinterest:
    'https://res.cloudinary.com/tellmedia/image/upload/v1566811998/shopmyinfluens/icons/pinterest.svg',
  site: 'https://res.cloudinary.com/tellmedia/image/upload/v1566481795/shopmyinfluens/icons/web.svg',
  snapchat:
    'https://res.cloudinary.com/tellmedia/image/upload/v1566811998/shopmyinfluens/icons/snapchat.svg',
}

export const firebaseConfig = config[env]